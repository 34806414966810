const awsConf = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Lnd7Y7HKB",
    "aws_user_pools_web_client_id": "4326mj419shkk00p1n85ivugrg",
    "oauth": {
        "domain": 'billpocket-oauth-brain-demo.auth.us-east-1.amazoncognito.com',
        "scope": ['email', 'openid'],
        "redirectSignIn": 'https://test.covenant.billpocket.com/',
        "redirectSignOut": 'https://test.covenant.billpocket.com/',
        "responseType": 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    "federationTarget": "COGNITO_USER_POOLS"
};

export default awsConf;
